























































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import Session from '@/models/graphql/Session';
import SettingsBoxItem from '@/views/settings/SettingsBoxItem.vue';
import { namespace, State } from 'vuex-class';
import GenericEvent from '@/utils/types/GenericEvent';
import { mixins } from 'vue-class-component';
import VueBaseNotify from '@/utils/widgets/VueBaseNotify';
import NotificationEventType from '@/utils/enums/notification/NotificationEventType';
import SessionSettingType from '@/utils/enums/SessionSettingType';
import SessionVideoSettingsModal from '@/components/modals/SessionVideoSettingsModal.vue';
import InputText from '@/components/InputText.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ToastActionType from '@/utils/enums/ToastActionType';
import ToastActionParams from '@/utils/types/ToastActionParams';
import { format } from 'date-fns';

const sessionStore = namespace('SessionStore');
const notificationStore = namespace('NotificationStore');
const toastStore = namespace('ToastStore');

@Component({
  components: {
    ButtonComponent,
    ButtonIconComponent,
    FontAwesomeComponent,
    InputText,
    SessionVideoSettingsModal,
    SettingsBoxItem,
  },
})
export default class SessionSettingsComponent extends mixins(BreakpointWrapper, VueBaseNotify) {
  @Prop({
    required: true,
    default: null,
  })
  sessionData!: Session;

  @notificationStore.Mutation
  unsubscribeGenericEventPointer!: (channel: string) => void;

  @sessionStore.Action
  private updateSession!: (payload: Partial<Session>) => Promise<Session | undefined>;

  @notificationStore.Action
  private genericEvent!: (payload: {
    channel: string;
    customCallback?: (event: GenericEvent) => void;
  }) => void;

  @notificationStore.Action
  private triggerGenericEvent!: (params: {
    entityId: string;
    type: string;
    extra: string;
    channels: string[];
  }) => void;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  @State
  private dateLocale!: Locale;

  private videoEnabled = false;

  private chatEnabled = false;

  private qnaEnabled = false;

  private pollsEnabled = false;

  private videoSettingLoading = false;

  private chatSettingLoading = false;

  private qnaSettingLoading = false;

  private pollsSettingLoading = false;

  private settingType = SessionSettingType;

  private session = this.sessionData;

  private selectedVideoType: string | null = null;

  private videoType = {
    LIVE: 'live',
    RECORDED: 'recorded',
  };

  private get isLive(): boolean {
    return this.session ? !!(this.session.videoLiveEnabled && this.session.videoType === 'live') : false;
  }

  private get isRecorded(): boolean {
    return this.session
      ? !!(this.session.videoArchiveEnabled
            && this.session.videoType === 'recorded')
      : false;
  }

  private get videoEmbedUrl(): string {
    if (this.session) {
      if (this.session.videoType === 'recorded'
          && this.session.videoArchiveEnabled
          && this.session.videoArchiveEmbedEndpoint) {
        return this.session.videoArchiveEmbedEndpoint;
      }
      if (this.session.videoType
          && this.session.videoType === 'live'
          && this.session.videoLiveEnabled
          && this.session.videoLiveEmbedEndpoint) {
        return this.session.videoLiveEmbedEndpoint;
      }
    }
    return '';
  }

  private get sessionVideoType(): string {
    let start = '';
    let end = '';
    if (this.isLive) {
      return `${this.$t('session-cms.settings.video.livestream')}`;
    } if (this.session.videoEnabledStartTime || this.session.videoEnabledEndTime) {
      if (this.session.videoEnabledStartTime) {
        start = `${format(new Date(this.session.videoEnabledStartTime), this.$t('app.date.defaultDateTimeFormat') as string,
          { locale: this.dateLocale })}`;
      } if (this.session.videoEnabledEndTime) {
        end = `${format(new Date(this.session.videoEnabledEndTime), this.$t('app.date.defaultDateTimeFormat') as string,
          { locale: this.dateLocale })}`;
      }
      if (start && end) {
        return `${start} ${this.$t('app.date.dateTimeSeparator')} ${end}`;
      } if (start) {
        return `${this.$tc('session-cms.settings.video.start', 0, { date: start })}`;
      } if (end) {
        return `${this.$tc('session-cms.settings.video.end', 0, { date: end })}`;
      }
    }
    return `${this.$t('session-cms.settings.video.on-demand')}`;
  }

  created(): void {
    this.notifyEvents = [
      NotificationEventType.SESSION_SETTINGS_CHANGE,
    ];
  }

  mounted(): void {
    this.onSessionChange();
    if (this.session) {
      this.session = this.sessionData;
      this.videoEnabled = !!this.session.videoEnabled && (this.isLive || this.isRecorded);
      this.genericEvent({
        channel: `session-settings-${this.session.uid}`,
      });
    }
  }

  beforeDestroy(): void {
    if (this.session) {
      this.unsubscribeGenericEventPointer(`session-settings-${this.session.uid}`);
    }
  }

  protected notificationCallback(event: GenericEvent): void {
    const extraData = JSON.parse(event.extra);
    if (event && event.type === NotificationEventType.SESSION_SETTINGS_CHANGE) {
      switch (extraData.settingType) {
        case this.settingType.CHAT:
          this.chatEnabled = extraData.isActivated;
          break;
        case this.settingType.QNA:
          this.qnaEnabled = extraData.isActivated;
          break;
        case this.settingType.POLLS:
          this.pollsEnabled = extraData.isActivated;
          break;
        default:
          break;
      }
    }
  }

  protected showToast(type: ToastActionType): void {
    this.addNewAction({ type });
  }

  @Watch('session', { deep: true })
  private onSessionChange(): void {
    if (this.session) {
      this.videoEnabled = !!this.session.videoEnabled && (this.isLive || this.isRecorded);
      this.chatEnabled = !!this.session.showLiveChat;
      this.qnaEnabled = !!this.session.showQNA;
      this.pollsEnabled = !!this.session.showPolls;
      if (this.isLive) {
        this.selectedVideoType = this.videoType.LIVE;
      } else if (this.isRecorded) {
        this.selectedVideoType = this.videoType.RECORDED;
      }
    }
  }

  private onToggle(settingType: string): void {
    const partialSession = {
      uid: this.session.uid,
    } as unknown as Session;
    switch (settingType) {
      case this.settingType.VIDEO:
        if (!this.videoEmbedUrl && !this.videoEnabled) {
          this.$bvModal.show(`video-settings-modal-${this.session.uid}`);
        } else if (this.videoEnabled) {
          this.videoEnabled = false;
          this.videoSettingLoading = true;
          partialSession.videoEnabled = false;
          this.updateSession(partialSession)
            .then(() => {
              this.videoSettingLoading = false;
              this.triggerGenericEvent({
                channels: [`session-settings-${this.session.uid}`],
                type: NotificationEventType.SESSION_VIDEO_CHANGE,
                entityId: this.session.uid,
                extra: JSON.stringify({
                  disabled: true,
                }),
              });
            });
        } else if (!this.videoEnabled) {
          this.videoEnabled = true;
          this.videoSettingLoading = true;
          partialSession.videoEnabled = true;
          this.updateSession(partialSession)
            .then(() => {
              this.videoSettingLoading = false;
              this.triggerGenericEvent({
                channels: [`session-settings-${this.session.uid}`],
                type: NotificationEventType.SESSION_VIDEO_CHANGE,
                entityId: this.session.uid,
                extra: JSON.stringify({
                  disabled: false,
                }),
              });
            });
        }
        break;
      case this.settingType.CHAT:
        this.chatEnabled = !this.chatEnabled;
        this.chatSettingLoading = true;
        partialSession.showLiveChat = this.chatEnabled;
        this.updateSession(partialSession)
          .then(() => {
            this.chatSettingLoading = false;
            this.sendSettingsChangeNotification(this.settingType.CHAT, this.chatEnabled);
          });
        break;
      case this.settingType.QNA:
        this.qnaEnabled = !this.qnaEnabled;
        this.qnaSettingLoading = true;
        partialSession.showQNA = this.qnaEnabled;
        this.updateSession(partialSession)
          .then(() => {
            this.qnaSettingLoading = false;
            this.sendSettingsChangeNotification(this.settingType.QNA, this.qnaEnabled);
          });
        break;
      case this.settingType.POLLS:
        this.pollsEnabled = !this.pollsEnabled;
        this.pollsSettingLoading = true;
        partialSession.showPolls = this.pollsEnabled;
        this.updateSession(partialSession)
          .then(() => {
            this.pollsSettingLoading = false;
            this.sendSettingsChangeNotification(this.settingType.POLLS, this.pollsEnabled);
          });
        break;
      default:
        break;
    }
  }

  private sendSettingsChangeNotification(settingType: string, isActivated: boolean): void {
    this.triggerGenericEvent({
      channels: [`session-settings-${this.session.uid}`],
      type: NotificationEventType.SESSION_SETTINGS_CHANGE,
      entityId: this.session.uid,
      extra: JSON.stringify({
        settingType,
        isActivated,
      }),
    });
  }

  private onVideoUrlChange(args: {
    videoUrl: string;
    selectedType: 'recorded' | 'live';
    endDate: string;
    startDate: string;
  }): void {
    const partialSession = {
      uid: this.session.uid,
    } as unknown as Session;
    partialSession.videoType = args.selectedType;
    this.selectedVideoType = args.selectedType;
    if (args.selectedType === 'live') {
      partialSession.videoLiveEnabled = true;
      partialSession.videoArchiveEnabled = false;
      partialSession.videoLiveEmbedEndpoint = args.videoUrl;
      partialSession.videoEnabledStartTime = null;
      partialSession.videoEnabledEndTime = null;
    } else if (args.selectedType === 'recorded') {
      partialSession.videoLiveEnabled = false;
      partialSession.videoArchiveEnabled = true;
      partialSession.videoArchiveEmbedEndpoint = args.videoUrl;
      partialSession.videoEnabledStartTime = args.startDate;
      partialSession.videoEnabledEndTime = args.endDate;
    }
    this.videoSettingLoading = true;
    this.videoEnabled = true;

    this.updateSession(partialSession)
      .then(() => {
        this.videoSettingLoading = false;
        this.triggerGenericEvent({
          channels: [`session-settings-${this.session.uid}`],
          type: NotificationEventType.SESSION_VIDEO_CHANGE,
          entityId: this.session.uid,
          extra: JSON.stringify({
            isLive: args.selectedType === 'live',
            isRecorded: args.selectedType === 'recorded',
            videoUrl: args.videoUrl,
            videoEnabledEndTime: args.endDate,
            videoEnabledStartTime: args.startDate,
          }),
        });
      });
  }

  private onEditVideoClick(): void {
    this.$bvModal.show(`video-settings-modal-${this.session.uid}`);
  }

  private async copyToClipBoard(): Promise<void> {
    try {
      await navigator.clipboard.writeText(this.videoEmbedUrl);
      this.showToast(ToastActionType.COPY_TO_CLIPBOARD);
    } catch (err) {
      this.showToast(ToastActionType.FAILED_COPY_TO_CLIPBOARD);
    }
  }
}
