





























import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Community from '@/models/graphql/Community';
import ButtonComponent from '@/components/ButtonComponent.vue';

@Component({
  components: { ButtonComponent },
})
export default class InvalidSignIn extends Vue {
  @Getter
  protected readonly community!: Community;

  @Getter
  protected readonly authEmail!: string;

  @Getter
  private readonly isUnifyExhibitorPortal!: boolean;

  // eslint-disable-next-line class-methods-use-this
  private get contactSupportHref(): string {
    return `<a class="text-neutral-n-8-dark-ink text-underline"
                    href="mailto:${this.community?.organizer?.secondaryEmail}">${this.community?.organizer?.secondaryEmail}</a>`;
  }

  private onTryAgainClick(): void {
    this.$router.push({ name: 'sign-in' });
  }

  private onGuestClick(): void {
    this.$router.replace({ path: '/' });
  }
}
