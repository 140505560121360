










































































































































import { Component, Prop } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import InputText from '@/components/InputText.vue';
import RouteHelper from '@/utils/helpers/RouteHelper';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import SizeDigit from '@/utils/enums/SizeDigit';
import SegmentedControlsComponent from '@/components/SegmentedControlsComponent.vue';
import SegmentedElementParams from '@/utils/types/SegmentedElementParams';
import PickerDateComponent from '@/components/calendar/PickerDateComponent.vue';
import PickerDateTimeComponent from '@/components/calendar/PickerDateTimeComponent.vue';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import { State } from 'vuex-class';
import { format } from 'date-fns';

@Component({
  components: {
    PickerDateTimeComponent,
    PickerDateComponent,
    SegmentedControlsComponent,
    ButtonIconComponent,
    FontAwesomeComponent,
    InputText,
    StandardModal,
    ButtonComponent,
  },
})
export default class SessionVideoSettingsModal extends BreakpointWrapper {
  @Prop({ default: '' })
  modalId!: string;

  @Prop({ default: 'md' })
  size!: string;

  @Prop({
    required: true,
    default: '',
  })
  sessionVideoUrl!: string;

  @Prop({
    required: true,
    default: false,
  })
  isLive!: boolean;

  @Prop({
    required: true,
    default: false,
  })
  isRecorded!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  defaultStartTime!: string | null;

  @Prop({
    required: false,
    default: null,
  })
  defaultEndTime!: string | null;

  @Prop({
    required: false,
    default: null,
  })
  sessionStartTime!: string | null;

  @Prop({
    required: false,
    default: null,
  })
  sessionEndTime!: string | null;

  @State
  private dateLocale!: Locale;

  @State
  private selectedTzName!: string;

  private videoUrl = this.sessionVideoUrl;

  private videoType = {
    LIVESTREAM: 'livestream',
    ON_DEMAND: 'onDemand',
    TIMEFRAME: 'timeframe',
  };

  private selected = this.videoType.LIVESTREAM;

  private startDate: Date | null = null;

  private endDate: Date | null = null;

  private tabs = [
    {
      text: `${this.$t('session-cms.settings.video.livestream')}`,
      key: this.videoType.LIVESTREAM,
      activeState: this.selected === this.videoType.LIVESTREAM,
      icon: '',
      iconColor: '',
      size: SizeDigit.S_40,
    },
    {
      text: `${this.$t('session-cms.settings.video.on-demand')}`,
      key: this.videoType.ON_DEMAND,
      activeState: this.selected === this.videoType.ON_DEMAND,
      icon: '',
      iconColor: '',
      size: SizeDigit.S_40,
    },
    {
      text: `${this.$t('session-cms.settings.video.timeframe')}`,
      key: this.videoType.TIMEFRAME,
      activeState: this.selected === this.videoType.TIMEFRAME,
      icon: '',
      iconColor: '',
      size: SizeDigit.S_40,
    },
  ]

  // eslint-disable-next-line class-methods-use-this
  private isUrlValid(url: string): boolean {
    return RouteHelper.isUrlValid(url);
  }

  private onModalShow(): void {
    if (this.isLive) {
      this.selected = this.videoType.LIVESTREAM;
    } else {
      this.startDate = this.defaultStartTime ? new Date(this.defaultStartTime) : DateTimeHelper.getCurrentDateTime();
      this.endDate = this.defaultEndTime ? new Date(this.defaultEndTime) : DateTimeHelper.getCurrentDateTime();
      if (this.defaultStartTime || this.defaultEndTime) {
        this.selected = this.videoType.TIMEFRAME;
      } else {
        this.selected = this.videoType.ON_DEMAND;
      }
    }
    this.tabs.forEach((v) => {
      v.activeState = this.selected === v.key;
    });
    this.videoUrl = this.sessionVideoUrl;
  }

  private onConfirm(): void {
    this.$bvModal.hide(`${this.modalId}`);
    this.$emit('on-confirm', {
      videoUrl: this.videoUrl,
      selectedType: this.sessionVideoType,
      startDate: this.startDate
        ? format(new Date(this.startDate), DateTimeHelper.TIME_FORMAT_ISO_8601) : null,
      endDate: this.endDate
        ? format(new Date(this.endDate), DateTimeHelper.TIME_FORMAT_ISO_8601) : null,
    });
  }

  private onCancel(): void {
    this.$bvModal.hide(`${this.modalId}`);
    this.$emit('on-cancel');
  }

  private switchTab(tab: SegmentedElementParams): void {
    this.selected = tab.key;
    this.tabs.forEach((v) => {
      v.activeState = tab.key === v.key;
    });
    if (this.selected === this.videoType.TIMEFRAME) {
      this.endDate = this.defaultEndTime ? new Date(this.defaultEndTime) : DateTimeHelper.utcToZonedTimeDate(
        `${this.sessionEndTime}Z`,
        this.selectedTzName,
      );
      this.startDate = this.defaultStartTime ? new Date(this.defaultStartTime) : DateTimeHelper.utcToZonedTimeDate(
        `${this.sessionStartTime}Z`,
        this.selectedTzName,
      );
    } else {
      this.startDate = null;
      this.endDate = null;
    }
  }

  private selectedStartDate(data: { date: Date; timestamp: number; text: string }): void {
    this.startDate = DateTimeHelper.toLocal(data.date);
  }

  private selectedEndDate(data: { date: Date; timestamp: number; text: string }): void {
    this.endDate = DateTimeHelper.toLocal(data.date);
  }

  private get sessionVideoType(): string | null {
    switch (this.selected) {
      case this.videoType.LIVESTREAM:
        this.startDate = null;
        this.endDate = null;
        return 'live';
      case this.videoType.TIMEFRAME:
      case this.videoType.ON_DEMAND:
        return 'recorded';
      default:
        return null;
    }
  }
}
