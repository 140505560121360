




















































































import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { Component } from 'vue-property-decorator';
import { helpers, minLength, required } from 'vuelidate/lib/validators';
import AlertComponent from '@/components/AlertComponent.vue';
import { validationMixin } from 'vuelidate';
import AlertType from '@/utils/enums/AlertType';
import { Getter, namespace } from 'vuex-class';
import ButtonComponent from '@/components/ButtonComponent.vue';
import InputText from '@/components/InputText.vue';
import { EMAIL } from '@/utils/constants/Regex';

const signInStore = namespace('SignInStore');
const authenticationStore = namespace('AuthenticationStore');
const emailValidation = helpers.regex('email', EMAIL);

@Component({
  components: {
    ButtonComponent,
    InputText,
    AlertComponent,
  },
  data(): object {
    return {
      firstSubmit: false,
      emailError: false,
      emailNotUnique: false,
      serviceError: false,
    };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      email: {
        required,
        minLength: minLength(3),
        emailValidation,
      },
    },
  },
})
export default class SignInEmailDisambiguated extends VueBaseWidget {
  @signInStore.Action
  private sendTriggerChangeEmail!: (payload: { email: string }) => Promise<boolean | null>;

  @authenticationStore.Action
  private logout!: () => Promise<void>;

  @Getter
  private readonly isUnifyExhibitorPortal!: boolean;

  private alertType = AlertType;

  private disableSubmit = false;

  private form = {
    email: '',
  };

  created(): void {
    if (this.authUser && this.authUser.email) {
      // eslint-disable-next-line no-underscore-dangle
      if (this.authUser._needsEmailDisambiguated) {
        this.form.email = this.authUser.email;
      } else {
        this.$router.replace({ path: '/' });
      }
    } else {
      this.$router.replace({ name: 'sign-in' });
    }
  }

  private emailValidation(): void {
    this.$data.emailNotUnique = false;
    if (this.$v) {
      this.$data.emailError = this.$v.form.email ? this.$v.form.email.$invalid : false;
    }
  }

  private onSubmit(): void {
    this.$data.firstSubmit = true;
    this.disableSubmit = true;
    this.emailValidation();
    if (this.form.email && this.form.email !== '' && !this.$data.emailError && this.community.code) {
      this.sendTriggerChangeEmail({
        email: this.form.email,
      })
        .then((response: boolean | null) => {
          if (response) {
            // eslint-disable-next-line no-underscore-dangle
            this.authUser._needsEmailDisambiguated = false;
            this.$router.push({ path: '/' });
          } else if (response === false) {
            this.$data.emailNotUnique = true;
          } else {
            this.$data.serviceError = true;
          }
          this.disableSubmit = false;
        })
        .catch(() => {
          this.$data.serviceError = true;
          this.disableSubmit = false;
        });
    } else {
      this.disableSubmit = false;
    }
  }

  private onGuestClick(): void {
    this.logout()
      .then(() => {
        this.$router.replace({ path: '/' });
      });
  }
}
